/* eslint react/prop-types: 0 */
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Navbar from '../components/navbar/navbarvn';
import Footer from '../components/homepagevn/Footer';
import Banner from '../components/san-pham/productBanner';
import Lienhe from '../components/san-pham/lien-he';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    marginTop: '5rem',
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    display: 'flex',
    position: 'relative',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
  },
  header2: {
    fontSize: '1.14rem',
    color: '#888888',
    marginBottom: theme.spacing(5),
  },
  textFont: {
    marginTop: '1rem',
    fontSize: '1.14rem',
    fontWeight: 'bold',
    textDecoration: 'none !important',
  },
  textFont1: {
    fontSize: '0.875rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionButton: {
    marginTop: theme.spacing(5),
    testAlign: 'center',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    fontSize: '1.14rem',
    textDecoration: 'none',
    padding: '1rem 1.5rem 1rem 1.5rem',
    borderRadius: '41px',
    color: 'black',
    textTransform: 'uppercase',
  },
  actionButton1: {
    marginTop: theme.spacing(5),
    testAlign: 'center',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    fontSize: '1.14rem',
    textDecoration: 'none',
    padding: '1rem 1.5rem 1rem 1.5rem',
    borderRadius: '3px',
    color: 'white',
    backgroundColor: '#f2ae1c',
  },
  quotationContainer: {
    width: '100%',
    marginTop: '6rem',
    height: 'auto',
    backgroundColor: '#1b1c46',
  },
}));

export default function SanPham({ data }) {
  const classes = useStyles();

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title> SẢN PHẨM</title>
      </Helmet>
      <Navbar />
      <Banner />
      <Container maxWidth="lg">
        <Grid container spacing={3} className={classes.mainGrid}>
          {data.allMdx.edges.map((edge) => (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Card style={{ height: '100%', flex: '1 0' }}>
                <Link href={edge.node.frontmatter.slug} className={classes.blogPost}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt=""
                      height="263"
                      image={edge.node.frontmatter.feature_img}
                      title={edge.node.frontmatter.title}
                    />
                    <Typography gutterBottom variant="h6" component="h2" className={classes.textFont} align="center">
                      <a href={edge.node.frontmatter.slug} className={classes.actionButton}>
                        {' '}
                        {edge.node.frontmatter.title}
                        {' '}
                      </a>
                    </Typography>
                  </CardActionArea>
                </Link>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <div className={classes.quotationContainer}>
        <Grid container>
          <Grid item xs={1} lg={1} />
          <Grid item xs={10} lg={10}>
            <br />
            <Typography variant="h6" align="center" style={{ marginTop: '3rem', color: 'white' }}>
              Công ty TNHH Công Nghiệp Chính Đại với 20 năm kinh nghiệm trong
              lĩnh vực sản xuất các loại ống thép, đi đầu trong việc cung cấp ống thép
              cứu hỏa chuyên dụng ở Việt Nam
            </Typography>
            <br />
            <Typography variant="h4" align="center" style={{ color: 'white' }}>
              NHẬN BÁO GIÁ NGAY BÂY GIỜ
            </Typography>
            <br />
            <Typography gutterBottom variant="h6" component="h2" className={classes.textFont} align="center" style={{ marginBottom: '5rem' }}>
              <a href="/lien-he" className={classes.actionButton1}> Liên hệ ngay với chúng tôi </a>
            </Typography>
          </Grid>
          <Grid item xs={1} lg={1} />
        </Grid>
      </div>
      <Lienhe />
      <Footer />
    </>
  );
}

export const query = graphql`
  query {
    allMdx (
    filter: {
      frontmatter: {template: {eq: "sanphamvn"}}
    }
     sort: {
       fields: [frontmatter___date]
       order: DESC
     }
   ) {
     edges {
       node {
         frontmatter {
           slug
           title
           subtitle
           date
           feature_img
         }
       }
     }
   }
  }
`;
