/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const styles = (theme) => ({
  root: {
    backgroundPosition: 'center',
    height: 380,
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
      fontSize: '1.14rem',
    },
  },
  button: {
    backgroundColor: '#f2ae1c',
    fontFamily: [
      'Roboto', 'Helvetica Neue', 'Arial',
    ].join(','),
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '5rem',
      fontSize: '1.14rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      paddingLeft: '0.6rem',
      paddingRight: '0.6rem',
      borderRadius: 4,
      width: '20%',
    },
  },
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
      fontSize: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      fontSize: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  formContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  subTitle: {
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    color: '#888',
    fontSize: '1.14rem',
    paddingTop: '1rem',
  },
  paddingContainer: {
    paddingTop: theme.spacing(20),
  },
});

function Contact(props) {
  const { classes } = props;

  return (
    <section style={{ backgroundColor: '#fff' }}>
      <Container className={classes.root}>
        <Grid container className={classes.paddingContainer}>
          <Grid item xs={1} lg={1} />
          <Grid item xs={10} lg={10}>
            <Typography variant="h4" marked="center" component="h2" className={classes.title}>
              LIÊN HỆ NGAY VỚI CHÚNG TÔI
            </Typography>
            <Typography className={classes.subTitle}>
              Nhận được tư vấn về các sản phẩm,giải pháp chất lượng với giá tốt nhất
            </Typography>
          </Grid>
          <Grid item xs={1} lg={1} />
          <Grid item xs={1} lg={1} />
          <Grid item xs={10}>
            <div>
              <iframe src="https://chinhdai.getflycrm.com/api/forms/viewform/?key=BLu1M3Zdn8oV7t83ElyJNVlYbxF1YYYuiV8eruHJJpSur6IUCe" title="form contact" width="100%" height="300px" frameBorder="0" marginHeight="20px" marginWidth="0">Loading ...</iframe>
            </div>
          </Grid>
          <Grid item xs={1} lg={1} />
        </Grid>
      </Container>
    </section>
  );
}

export default withStyles(styles)(Contact);
